import React from 'react'
import { connect } from 'react-redux'
import HeaderTitle from '../Header/HeaderTitle'
import Select, { components } from 'react-select'
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Firefly from 'firefly-sdk-ts'
import { initializeFireflySDK } from '../../common/FireflyHelper'
import apiConfig from '../../config/apiConfig'
import DeviceConfigurationHelpDialog from './DeviceConfigurationHelpDialog'
import { DeviceConfigurationConfirmModal } from './DeviceConfigurationConfirmModal'
import { DeviceConfigurationSuccessModal } from './DeviceConfigurationSuccessModal'
import {
  clearResultMessage,
  getActiveSheets,
  getConfigurationTypes,
  initiateConfigurationStep,
  toggleStatusOpen,
} from '../../store/DeviceConfiguration/actionCreator'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export class DeviceConfiguration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSheet: '',
      activeSheets: [],
      activeSheetId: '',
      activeSheetLink: '',
      buttonEnabled: false,
      configurationLocations: [],
      configurationStep: '',
      configurationStepsList: [],
      configurationType: '',
      confirmInput: '',
      confirmKey: '',
      confirmOpen: false,
      confirmRemove: false,
      helpOpen: false,
      loading: true,
      menuOpen: false,
      resetButtonEnabled: false,
      resultMessage: '',
      selectedLocations: [],
      statusOpen: false,
      typesAndLocationsList: [],
    }
    initializeFireflySDK()
    this.baseState = { ...this.state }
    this.handleConfirmClose = this.handleConfirmClose.bind(this)
    this.handleConfirmInput = this.handleConfirmInput.bind(this)
    this.handleHelpClose = this.handleHelpClose.bind(this)
    this.handleHelpOpen = this.handleHelpOpen.bind(this)
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleStatusClose = this.handleStatusClose.bind(this)
    this.handleLocationSelect = this.handleLocationSelect.bind(this)
    this.handleOnConfigure = this.handleOnConfigure.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
  }

  async componentDidMount() {
    await this.props.getActiveSheets()
    const { userInfo } = this.props
    Firefly.setGlobalDataLayer(
      {
        appState: {
          globalPage: 'Device Configuration',
          pageName: 'Device Configuration',
          pageType: 'Device Configuration',
        },
      },
      apiConfig.fireflyConfig.persistMethod,
    )
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: {
        name: 'ziptie',
      },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Device Configuration' },
      user: { id: userInfo.email },
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeSheets !== nextProps.activeSheets) {
      this.setState({
        activeSheets: nextProps.activeSheets,
        loading: false,
      })
    }
    this.setState({
      typesAndLocationsList: nextProps.typesAndLocationsList,
    })
    if (this.props.resultMessage !== nextProps.resultMessage) {
      this.setState({
        resultMessage: nextProps.resultMessage,
      })
    }
    if (this.props.statusOpen !== nextProps.statusOpen) {
      this.setState({
        statusOpen: nextProps.statusOpen,
      })
    }
  }

  static defaultProps = {
    userInfo: {
      lanId: undefined,
      email: undefined,
      memberOf: undefined,
    },
  }

  handleLocationSelect(selected) {
    let allClick = selected.some((select) => select.value === '*')
    let allState = this.state.selectedLocations.some(
      (select) => select.value === '*',
    )
    if (allClick && !allState) {
      let allSelected = this.state.configurationLocations.map((location) => ({
        value: location,
        label: location,
      }))
      allSelected.unshift(selected[selected.length - 1])
      this.setState(
        {
          confirmInput: '',
          confirmKey: '',
          menuOpen: true,
          selectedLocations: allSelected,
        },
        function () {
          this.validateForm()
        },
      )
    } else if (!allClick && allState) {
      this.setState(
        {
          confirmInput: '',
          confirmKey: '',
          menuOpen: true,
          selectedLocations: [],
        },
        function () {
          this.validateForm()
        },
      )
    } else if (
      allClick &&
      allState &&
      selected.length < this.state.selectedLocations.length
    ) {
      const unselectAll = selected.filter((select) => {
        return select.value !== '*'
      })
      this.setState(
        {
          confirmInput: '',
          confirmKey: '',
          menuOpen: true,
          selectedLocations: unselectAll,
        },
        function () {
          this.validateForm()
        },
      )
    } else {
      this.setState(
        {
          confirmInput: '',
          confirmKey: '',
          menuOpen: true,
          selectedLocations: selected,
        },
        function () {
          this.validateForm()
        },
      )
    }
  }

  handleOnConfigure(event) {
    event.preventDefault()
    const { userInfo } = this.props
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addTaskSuccessEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'click' },
      event: { type: 'click', name: 'Configure Devices - Submit' },
      user: { id: userInfo.email },
    })
    const filteredLocations = this.state.selectedLocations.filter(
      (location) => {
        return location.value !== '*'
      },
    )
    const formattedLocationsList = filteredLocations.map(
      (location) => location.value,
    )
    if (
      ((this.state.configurationType.value.configurationName ===
        'Mist Access Point' &&
        this.state.configurationStep.value.stepDescription ===
          'Remove AP from Mist (optional)') ||
        (this.state.configurationType.value.configurationName ===
          'Mist Access Point - DC New' &&
          this.state.configurationStep.value.stepDescription ===
            'Remove AP from Mist') ||
        (this.state.configurationType.value.configurationName ===
          'Mist Access Point - DC Overhaul' &&
          this.state.configurationStep.value.stepDescription ===
            'Remove AP from Mist')) &&
      this.state.confirmOpen !== true &&
      this.state.confirmKey === ''
    ) {
      if (this.state.selectedLocations.length === 1) {
        this.setState({
          confirmKey: this.state.selectedLocations[0].value,
          confirmOpen: true,
        })
      } else {
        this.setState({
          confirmKey: 'Remove',
          confirmOpen: true,
        })
      }
    } else if (
      ((this.state.configurationType.value.configurationName ===
        'Mist Access Point' &&
        this.state.configurationStep.value.stepDescription ===
          'Remove AP from Mist (optional)') ||
        (this.state.configurationType.valueOf.configurationName ===
          'Mist Access Point - DC New' &&
          this.state.configurationStep.value.stepDescription ===
            'Remove AP from Mist') ||
        (this.state.configurationType.value.configurationName ===
          'Mist Access Point - DC Overhaul' &&
          this.state.configurationStep.value.stepDescription ===
            'Remove AP from Mist')) &&
      this.state.confirmOpen === true &&
      this.state.confirmKey !== ''
    ) {
      if (
        this.state.confirmInput.toLowerCase() ===
        this.state.confirmKey.toLowerCase()
      ) {
        this.setState({ confirmOpen: false })
        this.props.initiateConfigurationStep(
          this.state.configurationType.value.configurationName,
          'Step ' +
            this.state.configurationStep.value.stepOrder +
            ' - ' +
            this.state.configurationStep.value.stepDescription,
          this.state.activeSheetId,
          formattedLocationsList,
          this.props.userInfo.lanId,
        )
        this.props.toggleStatusOpen(true)
      } else {
        this.setState({ confirmInput: '' })
      }
    } else {
      this.props.initiateConfigurationStep(
        this.state.configurationType.value.configurationName,
        'Step ' +
          this.state.configurationStep.value.stepOrder +
          ' - ' +
          this.state.configurationStep.value.stepDescription,
        this.state.activeSheetId,
        formattedLocationsList,
        this.props.userInfo.lanId,
      )
      this.props.toggleStatusOpen(true)
    }
  }

  handleConfirmClose() {
    this.setState({
      confirmOpen: false,
      confirmInput: '',
      confirmKey: '',
    })
  }

  handleConfirmInput(value) {
    this.setState({ confirmInput: value })
  }

  handleHelpClose() {
    this.setState({ helpOpen: false })
  }

  handleHelpOpen() {
    this.setState({ helpOpen: true })
  }

  handleMenuOpen(open) {
    this.setState({
      menuOpen: open,
    })
  }

  handleStatusClose() {
    this.props.toggleStatusOpen(false)
  }

  resetForm() {
    this.setState({
      activeSheet: '',
      typesAndLocationsList: [],
      configurationType: '',
      configurationStepsList: [],
      configurationStep: '',
      configurationLocations: [],
      confirmInput: '',
      confirmKey: '',
      confirmRemove: false,
      selectedLocations: [],
      buttonEnabled: false,
      resetButtonEnabled: false,
      loading: false,
    })
  }

  validateForm() {
    if (
      this.state.activeSheet !== '' &&
      this.state.configurationType !== '' &&
      this.state.configurationStep !== '' &&
      this.state.selectedLocations.length > 0
    ) {
      this.setState({ buttonEnabled: true })
    } else {
      this.setState({ buttonEnabled: false })
    }
    if (this.state.activeSheet !== '') {
      this.setState({ resetButtonEnabled: true })
    } else {
      this.setState({ resetButtonEnabled: false })
    }
  }

  render() {
    const { userInfo } = this.props

    let modalTitle
    if (
      this.state.resultMessage !== undefined &&
      this.state.resultMessage.length > 1
    ) {
      modalTitle = 'Configuration Status'
    } else {
      modalTitle = ''
    }

    let mappedSteps
    console.log('configurationStepsList - ', this.state.configurationStepsList)
    if (this.state.configurationStepsList.length > 0) {
      mappedSteps = this.state.configurationStepsList.map((step) => {
        if (
          userInfo.memberOf.includes('APP-ZIPTIE-ADMIN') ||
          step.accessLevels.some((groupName) =>
            userInfo.memberOf.includes(groupName),
          )
        ) {
          return {
            label: 'Step ' + step.stepOrder + ' - ' + step.stepDescription,
            value: step,
          }
        } else {
          return {
            label: 'Step ' + step.stepOrder + ' - ' + step.stepDescription,
            value: step,
            isDisabled: true,
          }
        }
      })
    } else {
      mappedSteps = []
    }

    let availableLocations
    let allOption = {
      label: 'Select All',
      value: '*',
    }
    if (this.state.configurationLocations.length > 0) {
      availableLocations = this.state.configurationLocations.map(
        (location) => ({
          value: location,
          label: location,
        }),
      )
      if (this.state.configurationLocations.length > 1) {
        availableLocations.unshift(allOption)
      }
    } else {
      availableLocations = []
    }

    let confirmButtonEnabled = false
    if (this.state.confirmInput !== '') {
      confirmButtonEnabled = true
    }

    return (
      <div>
        <HeaderTitle title="Device Configuration" />
        {this.state.loading ? (
          <Grid
            container
            spacing={10}
            justifyContent={'center'}
            className="progress"
          >
            <CircularProgress />
            <Typography className="loading">&nbsp;Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={6}>
                <Card style={{ height: '75vh', overflowY: 'scroll' }}>
                  <Grid container className="cardBanner">
                    <Grid item sm={12} lg={4}>
                      CONFIGURE DEVICES
                    </Grid>
                  </Grid>
                  <CardActions
                    style={{
                      width: '100%',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography gutterBottom>
                        Select all applicable options for the devices you wish
                        to configure. <br />
                        Please note that each step takes a while to complete, so
                        please wait some time before attempting to retrieve the
                        status.
                      </Typography>
                      <Tooltip title={'Help'}>
                        <IconButton onClick={this.handleHelpOpen} size="large">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </CardActions>
                  <form
                    onSubmit={this.handleOnConfigure}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '6%',
                    }}
                  >
                    <Grid
                      container
                      style={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          flexBasis: '90%',
                        }}
                      >
                        <Select
                          value={this.state.activeSheet}
                          options={
                            this.state.activeSheets.length > 0
                              ? this.state.activeSheets.map((activeSheet) => ({
                                  label: activeSheet.name,
                                  value: activeSheet,
                                }))
                              : ''
                          }
                          placeholder="Scope Sheet"
                          onChange={(event) => {
                            const sheetId = event.value.id
                            const sheetLink = this.state.activeSheets.filter(
                              (sheet) => {
                                return sheet.id === sheetId
                              },
                            )[0].permalink
                            this.setState(
                              {
                                activeSheet: event,
                                activeSheetId: event.value.id,
                                activeSheetLink: sheetLink,
                                configurationType: '',
                                configurationStep: '',
                                configurationStepsList: [],
                                confirmInput: '',
                                confirmKey: '',
                                selectedLocations: [],
                                configurationLocations: [],
                              },
                              function () {
                                this.validateForm()
                                this.props.getConfigurationTypes(
                                  this.state.activeSheetId,
                                )
                              },
                            )
                          }}
                          inputProps={{
                            name: 'activeSheet',
                            id: 'activeSheet',
                          }}
                          style={{ width: '70%', margin: '2%' }}
                        />
                        <br />
                        <Select
                          // Configuration Type
                          value={this.state.configurationType}
                          options={
                            this.state.typesAndLocationsList.length > 0
                              ? this.state.typesAndLocationsList.map(
                                  (type) => ({
                                    label: type.configurationName,
                                    value: type,
                                  }),
                                )
                              : []
                          }
                          placeholder="Configuration Type"
                          onChange={(event) => {
                            this.setState(
                              {
                                configurationType: event,
                                configurationStepsList:
                                  event.value.configurationSteps,
                                configurationLocations: event.value.locations,
                                configurationStep: '',
                                confirmInput: '',
                                confirmKey: '',
                                selectedLocations: [],
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                          inputProps={{
                            name: 'configurationType',
                            id: 'configurationType',
                          }}
                          style={{ width: '70%', margin: '2%' }}
                        />
                        <br />
                        <Select
                          // Configuration Step
                          value={this.state.configurationStep}
                          options={mappedSteps}
                          placeholder="Configuration Step"
                          onChange={(event) => {
                            this.setState(
                              {
                                configurationStep: event,
                                confirmInput: '',
                                confirmKey: '',
                              },
                              function () {
                                this.validateForm()
                              },
                            )
                          }}
                          inputProps={{
                            name: 'configurationStep',
                            id: 'configurationStep',
                          }}
                          style={{ width: '70%', margin: '2%' }}
                        />
                        <br />
                        <Select
                          // List of Stores
                          isMulti
                          value={this.state.selectedLocations}
                          options={availableLocations}
                          placeholder="Configuration Locations"
                          onChange={this.handleLocationSelect}
                          inputProps={{
                            name: 'configurationLocations',
                            id: 'configurationLocations',
                          }}
                          style={{ width: '70%', margin: '2%' }}
                          blurInputOnSelect={false}
                          onFocus={() => {
                            this.handleMenuOpen(true)
                          }}
                          onBlur={() => {
                            this.handleMenuOpen(false)
                          }}
                          menuIsOpen={this.state.menuOpen}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          allowSelectAll={true}
                        />
                      </div>
                      <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        style={{
                          marginTop: '40px',
                          flexBasis: '90%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid
                          item
                          sm={12}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Button
                            variant="contained"
                            onClick={this.resetForm}
                            disabled={!this.state.resetButtonEnabled}
                            type="reset"
                          >
                            Reset
                          </Button>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          md={3}
                          lg={2}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Button
                            xs={12}
                            sm={3}
                            variant="contained"
                            color="primary"
                            disabled={!this.state.buttonEnabled}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                  <DeviceConfigurationHelpDialog
                    open={this.state.helpOpen}
                    handleHelpClose={this.handleHelpClose}
                  />
                  <DeviceConfigurationSuccessModal
                    open={this.state.statusOpen}
                    handleClose={this.handleStatusClose}
                    eventsMessage={this.state.resultMessage}
                    title={modalTitle}
                    selectedSheet={this.state.activeSheet.label}
                    selectedSheetLink={this.state.activeSheetLink}
                    clearMessage={this.props.clearResultMessage}
                  />
                  <DeviceConfigurationConfirmModal
                    confirmButtonEnabled={confirmButtonEnabled}
                    confirmInput={this.state.confirmInput}
                    confirmKey={this.state.confirmKey}
                    handleConfirmClose={this.handleConfirmClose}
                    handleConfirmInput={this.handleConfirmInput}
                    open={this.state.confirmOpen}
                    submitConfirm={this.handleOnConfigure}
                  />
                </Card>
              </Grid>
            </div>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activeSheets: state.deviceConfiguration.activeSheets,
    resultMessage: state.deviceConfiguration.resultMessage,
    statusOpen: state.deviceConfiguration.statusOpen,
    typesAndLocationsList: state.deviceConfiguration.typesAndLocationsList,
    userInfo: state.auth.userInfo,
  }
}

const mapDispatchToProps = {
  clearResultMessage,
  getActiveSheets,
  getConfigurationTypes,
  initiateConfigurationStep,
  toggleStatusOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceConfiguration)
